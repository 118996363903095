import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import Logo from "../../components/main-layout/Logo"
import { Browser } from '@capacitor/browser';

const Login: React.FunctionComponent = () => {
    const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const onLogin = async () => {
        await loginWithRedirect({
            async openUrl(url) {
                // Redirect using Capacitor's Browser plugin

                await Browser.open({
                    url,
                    windowName: "_self"
                });
            }
        });
    };

    useEffect(() => {
        const fetchTokenAndNavigate = async () => {
            if (isLoading) {
                return;
            }
        };
        fetchTokenAndNavigate();
    }, [isAuthenticated, navigate, loginWithRedirect, getAccessTokenSilently, isLoading]);

    return (
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item xs={12}>
                <Grid container justifyContent="center">
                    <Card sx={{ maxWidth: 500 }}>
                        <Logo />

                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Welcome to Sho Forms
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Access the platform to configure and oversee your personalized forms.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button onClick={onLogin} variant="contained" >Login</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Login;
