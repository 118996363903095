import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IForm } from '../types/forms';

const initialState: IForm = {
    formId: 0,
    formName: '',
    formDescription: '',
    schemaId: 0,
    formSchemaJson: '',
    layoutSchemaId: 0,
    formLayoutSchemaJson: '',
    customerId: 0,
    customerName: '',
    totalRecords: 0,
    formKeyFieldName: '',
    formKeyFieldFriendlyName: '',
    isReadOnlyMode: false,
};

export const selectedFormSlice = createSlice({
    name: 'selectedForm',
    initialState,
    reducers: {
        updateSelectedForm: (state, action: PayloadAction<IForm>) => {
            if (action.payload) {
                state.formId = action.payload.formId;
                state.formName = action.payload.formName;
                state.formDescription = action.payload.formDescription;
                state.schemaId = action.payload.schemaId;
                state.formSchemaJson = action.payload.formSchemaJson;
                state.layoutSchemaId = action.payload.layoutSchemaId;
                state.formLayoutSchemaJson = action.payload.formLayoutSchemaJson;
                state.customerId = action.payload.customerId;
                state.customerName = action.payload.customerName;
                state.totalRecords = action.payload.totalRecords;
                state.isReadOnlyMode = action.payload.isReadOnlyMode;
            } else {
                return initialState;
            }
        },
    },
});

export const { updateSelectedForm } = selectedFormSlice.actions;

export default selectedFormSlice.reducer;