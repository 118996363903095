import { IAppConfig } from "../types/common";

const apiUrl: string = process.env.REACT_APP_API_URL!;
const auth0Domain: string = process.env.REACT_APP_AUTH0_DOMAIN!;
const auth0ClientId: string = process.env.REACT_APP_AUTH0_CLIENTID!;
const auth0RedirectUri: string = process.env.REACT_APP_AUTH0_REDIRECTURI!;
const auth0Audience: string = process.env.REACT_APP_AUTH0_AUDIENCE!;
const dropBoxAccessCode: string = process.env.REACT_APP_DROPBOX_ACCESSCODEURL!;
const dropBoxRedirectUrl: string = process.env.REACT_APP_DROPBOX_REDIRECTURL!;
//const googleLogin: string = process.env.REACT_APP_GOOGLE_LOGINURL!;
//const dropboxLogin: string = process.env.REACT_APP_DROPBOX_LOGINURL!;
//const microsoftLogin: string = process.env.REACT_APP_MICROSOFT_LOGINURL!;
const appConfig: IAppConfig =
{
    API_URL: apiUrl,
    THEME_COLORS: {
        PRIMARY: localStorage.getItem("formsPrimaryColor") ?? "#1e88e5",
        SECONDARY: "#fc3"
    },
    AUTH0_DOMAIN: auth0Domain,
    AUTH0_CLIENTID: auth0ClientId,
    AUTH0_AUDIENCE: auth0Audience,
    AUTH0_REDIRECTURI: auth0RedirectUri,
    DROPBOX_ACCESSCODEURL: dropBoxAccessCode,
    DROPBOX_REDIRECTURL: dropBoxRedirectUrl,
    LOGO_URL: localStorage.getItem("logoUrl") ?? "/sampleLogo.jpeg",
    //GOOGLE_LOGIN: googleLogin,
    //DROPBOX_LOGIN: dropboxLogin,
    //MICROSOFT_LOGIN: microsoftLogin
}

export { appConfig };