import { Fragment, useState } from 'react';
import * as React from 'react';

import Grid from '@mui/material/Grid';

import FormGroupList from '../components/FormGroupList';
import PageHeader from '../components/PageHeader';
import AddFormGroupDialog from '../components/AddFormGroupDialog';
import { useStyles } from "../common/appStyles";
import { Button } from '@mui/material';
import { showSnackbar } from '../services/snackbar.service';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { PageEnum } from '../types/enums';
import { IFormGroup } from '../types/form-groups';
import { AddUpdateFormGroup, DeleteFormGroup } from '../services/form-group-service';
import { IAddUpdateFormGroupRequest, IDeleteFormGroupRequest } from '../types/forms';

function FormGroups(props) {
    const classes = useStyles();

    const userRole = useSelector((state: RootState) => state.roles);
    const currentPage = userRole.userPageAccess[PageEnum.FormGroups];
    const user = useSelector((state: RootState) => state.userDetail);

    const [showAddFormGroupDialog, setShowAddFormGroupDialog] = useState(false);
    const [showBackButton] = useState(false);
    const [selectedFormGroup, setSelectedFormGroup] = React.useState<IFormGroup>();

    const [pageTitle] = useState("Form Groups");
    const [titleText, setTitleText] = useState("");

    const onEditFormGroup = (row: IFormGroup) => {
        setTitleText("Update Form Groups");
        console.log('selected form group', row)
        setSelectedFormGroup(row);
        setShowAddFormGroupDialog(true);
    }

    const handleClose = () => {
        setShowAddFormGroupDialog(false)
    }

    const handleSave = async (formGroupRequest: IAddUpdateFormGroupRequest) => {
        try {
            await AddUpdateFormGroup(formGroupRequest);
            showSnackbar('Saved Successfully', 'success', 5000);
            setShowAddFormGroupDialog(false);
        } catch (error) {
            showSnackbar('Error saving data', 'error', 5000);
        }
    }

    const handleDelete = async (formGroupRequest: IDeleteFormGroupRequest) => {
        try {
            await DeleteFormGroup(formGroupRequest);
            showSnackbar('Deleted Successfully', 'success', 5000);
            setShowAddFormGroupDialog(false);
        } catch (error) {
            showSnackbar('Error saving data', 'error', 5000);
        }
    }

    const onBack = () => { }

    const onAddFormGroup = () => {
        setTitleText("Configure Form Group");
        setSelectedFormGroup({
            formGroupId: 0,
            formGroupName: "",
            formIdList: [],
            userIdList: [],
        });
        setShowAddFormGroupDialog(true);
    }

    return (
        <Fragment>
            <Grid
                container
                justifyContent={'center'}
                spacing={1}
                className={classes.container}
            >
                <Grid item sm={12} className='justify-center'>
                    <PageHeader title={pageTitle} showBackButton={showBackButton} onBackClick={onBack} />
                    {currentPage?.canAdd && currentPage?.canAdd &&
                        <Button className='btn-theme-toggle' onClick={() => onAddFormGroup()} variant="contained">
                            Create Form Group
                        </Button>}
                </Grid>
                {currentPage?.canView && <Grid item sm={12}>
                    {!showAddFormGroupDialog && <FormGroupList onEditFormGroup={onEditFormGroup} />}

                    {showAddFormGroupDialog && <AddFormGroupDialog
                        showDialog={showAddFormGroupDialog}
                        selectedFormGroup={selectedFormGroup}
                        titleText={titleText}
                        handleClose={handleClose}
                        handleSave={handleSave}
                        handleDelete={handleDelete} />}
                </Grid>}

                {!currentPage?.canView && <h1>You don't have access to Form Groups</h1>}
            </Grid>
        </Fragment>
    );
}

export default FormGroups;
