import { Fragment, useEffect, useState } from 'react';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { ICustomer } from '../types/customer';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { getCustomerList } from '../services/customer.service';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { PageEnum } from '../types/enums';

const CustomerList = ({ onEditCustomer, rows }) => {
    const isMobile = !useMediaQuery('(min-width:600px)');
    const user = useSelector((state: RootState) => state.userDetail);
    const userRole = useSelector((state: RootState) => state.roles);
    const currentPage = userRole.userPageAccess[PageEnum.Users];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [rows, setRows] = useState<ICustomer[]>([]);

    const getStyle = (color: string) => {
        return {
            width: '50px',
            height: '25px',
            border: '2px solid #2980b9',
            backgroundColor: "#" + color
        }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // const GetCustomerList = () => {
    //     const request = {
    //         userId: user.userId,
    //     };
    //     getCustomerList(request)
    //         .then(response => {
    //             setRows(response);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }

    // useEffect(() => {
    //     GetCustomerList();
    // }, []);

    return (
        <Fragment>
            <Grid
                container
                justifyContent={'center'}
                spacing={1}
            >
                <Grid item sm={12} className='minWidth'>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader size="small" aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2} key="name">Name</TableCell>
                                        <TableCell colSpan={2} key="primaryColor">Primary Color</TableCell>
                                        <TableCell colSpan={2} key="secondaryColor">Secondary Color</TableCell>
                                        <TableCell colSpan={2} key="logo">Logo</TableCell>
                                        <TableCell colSpan={1} key="isActive">Active</TableCell>
                                        {currentPage?.canEdit && <TableCell colSpan={3} key="actions" align="center">Actions</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row: ICustomer) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={row.customerId}>
                                                    <TableCell colSpan={2} key="name">{row.customerName}</TableCell>
                                                    <TableCell colSpan={2} key="primaryColor"><div style={getStyle(row.primaryColor)}   ></div></TableCell>
                                                    <TableCell colSpan={2} key="secondaryColor"><div style={getStyle(row.secondaryColor)} ></div></TableCell>
                                                    <TableCell colSpan={2} key="logo"><img src={row.logoUrl} width="75" height="auto"></img></TableCell>
                                                    <TableCell key="isActive">{row.isActive ? "Yes" : "No"}</TableCell>
                                                    <TableCell colSpan={2} key="actions" align="center">
                                                        {currentPage?.canEdit && isMobile && <EditIcon onClick={e => onEditCustomer(row)} className="fs20 mr6 sho-icon" color='primary' />}
                                                        {currentPage?.canEdit && !isMobile && <Button onClick={e => onEditCustomer(row)} className="sho-button ml10" color='primary' size="small" variant='outlined'>
                                                            <EditIcon className="fs20 mr6 sho-icon" color='primary' /> Edit Customer
                                                        </Button>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default CustomerList;
