import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Outlet } from "react-router-dom";
import { Button } from '@mui/material';
import { TopNav } from './main-layout/TopNav';
import { SideNav } from './main-layout/SideNav';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useAuth0 } from '@auth0/auth0-react';
import { appConfig } from '../services/config.service';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import indexedDBService from '../services/indexdb-service';

const SIDE_NAV_WIDTH = 240;

const LayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
        paddingLeft: SIDE_NAV_WIDTH
    }
}));

const LayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%'
});

export default function MiniDrawer() {

    const [open, setOpen] = React.useState(false);

    const { logout } = useAuth0();

    const user = useSelector((state: RootState) => state.userDetail);

    const onLogout = async () => {

        if (Capacitor.isNativePlatform()) {
            let currentLogo: string = localStorage.getItem("companyLogoUrl") ?? ""
            let currentColor: string = localStorage.getItem("formsPrimaryColor") ?? ""
            let currentLogo64: string = localStorage.getItem("logoBase64") ?? ""
            localStorage.clear();
            await indexedDBService.deleteItem('General', 'User')
            localStorage.setItem("companyLogoUrl", currentLogo)
            localStorage.setItem("formsPrimaryColor", currentColor)
            localStorage.setItem("logoBase64", currentLogo64)
            await logout({
                logoutParams: {
                    returnTo: appConfig.AUTH0_REDIRECTURI + 'login'
                },
                async openUrl(url) {
                    // Redirect using Capacitor's Browser plugin
                    await Browser.open({
                        url,
                        windowName: "_self"
                    });
                }
            });
        }
        else {
            logout({ logoutParams: { returnTo: window.location.origin + '/login' } });
            let currentLogo: string = localStorage.getItem("companyLogoUrl") ?? ""
            let currentColor: string = localStorage.getItem("formsPrimaryColor") ?? ""
            let currentLogo64: string = localStorage.getItem("logoBase64") ?? ""
            localStorage.clear();
            localStorage.setItem("companyLogoUrl", currentLogo)
            localStorage.setItem("formsPrimaryColor", currentColor)
            localStorage.setItem("logoBase64", currentLogo64)
        }
    };

    return (
        <>
            {user && user.userId > 0 && <TopNav onNavOpen={() => setOpen(true)} />}
            {user && user.userId > 0 && <SideNav
                onClose={() => setOpen(false)}
                open={open}
            />}

            {user && user.isActive && <LayoutRoot>
                <LayoutContainer>
                    <Outlet />
                </LayoutContainer>
            </LayoutRoot>}
            {user && user.userId > 0 && !user.isActive && <LayoutRoot>
                <LayoutContainer>
                    <h2>{user.message}</h2>
                </LayoutContainer>
            </LayoutRoot>}
            {user && !user.userId && !user.isActive && <LayoutRoot>
                <LayoutContainer>
                    <h2>{user.message}</h2>
                    <Button
                        onClick={onLogout}
                        color='primary'
                        variant='contained'
                        sx={{ width: "200px", margin: "0 auto" }}
                    >
                        Logout
                    </Button>
                </LayoutContainer>
            </LayoutRoot>}
        </>
    );
}