import { Fragment } from 'react';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import { useStyles } from "../common/appStyles";
import { Link } from 'react-router-dom';

export default function HomePage() {
    const classes = useStyles();

    return (
        <Fragment>
            <Grid
                container
                justifyContent={'center'}
                spacing={1}
                className={classes.container}
            >
                <Grid item sm={12}>
                    <h2>Welcome to Sho Forms. Please proceed to the <Link to="/form-list">Form List</Link> to get started.</h2>
                </Grid>
            </Grid>
        </Fragment>
    );
}