import { useEffect, useReducer, useState, useMemo } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { useStyles } from "../common/appStyles";
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { ICustomer } from '../types/customer';
import DragDropFileUpload from './DragDropImageUpload';
import { GetCustomerLogo } from '../services/customer.service';
import { IFormListItem } from '../types/forms';
import { GetFormList } from '../services/form-service';
import { showSnackbar } from '../services/snackbar.service';

const initialState = {
    customerId: 0,
    customerName: '',
    isActive: true,
    isDeleted: false,
    primaryColor: '',
    secondaryColor: '',
    cloudFileName: '',
    logoUrl: '',
    createdBy: 0,
    createdDate: null,
    modifiedBy: 0,
    modifiedDate: null,
    logo: null,
    formIdList: null,
    isValid: {
        customerName: false,
        primaryColor: false,
        secondaryColor: false,
        formIdList: false,
        logo: false
    },
    touched: {
        customerName: false,
        primaryColor: false,
        secondaryColor: false,
        formIdList: false
    },

};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD':
            return { ...state, [action.field]: action.value };
        case 'SET_VALID':
            return { ...state, isValid: { ...state.isValid, [action.field]: action.isValid } };
        case 'SET_TOUCHED':
            return { ...state, touched: { ...state.touched, [action.field]: true } };
        default:
            throw new Error();
    }
}

export default function CustomerDialog(props) {
    const { showDialog, selectedCustomer, titleText, handleClose, handleSave } = props;
    const customerId = selectedCustomer.customerId;
    const classes = useStyles();
    const user = useSelector((state: RootState) => state.userDetail);
    const [Forms, setForms] = useState<IFormListItem[]>([]);

    const base64ToBlob = (base64: string): Blob => {
        const byteCharacters = atob(base64);
        const byteArrays: number[] = [];
        for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i));
        }
        return new Blob([new Uint8Array(byteArrays)], { type: 'image/png' });
    };
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        customerName: selectedCustomer.customerName,
        isActive: selectedCustomer.isActive,
        isDeleted: selectedCustomer.isDeleted,
        primaryColor: selectedCustomer.primaryColor,
        secondaryColor: selectedCustomer.secondaryColor,
        cloudFileName: selectedCustomer.cloudFileName,
        logoUrl: selectedCustomer.logoUrl,
        createdBy: user.userId,
        createdDate: selectedCustomer.createdDate,
        modifiedBy: user.userId,
        modifiedDate: selectedCustomer.modifiedDate,
        logo: '',
        formIdList: selectedCustomer.formIdList

    });

    const { customerName, isActive, isDeleted, primaryColor, secondaryColor, cloudFileName, logoUrl, createdBy, createdDate, modifiedBy, modifiedDate, isValid, touched, logo, formIdList } = state;

    const setField = (field, value) => {
        dispatch({ type: 'SET_FIELD', field, value });
        validateField(field, value);
    };

    const setTouched = (field) => {
        dispatch({ type: 'SET_TOUCHED', field });
    };



    const validateField = (field, value) => {
        let isValid = false;
        switch (field) {
            case 'customerName':
                isValid = isValidCustomerName(value);
                break;
            case 'primaryColor':
                isValid = isValidColor(value);
                break;
            case 'secondaryColor':
                isValid = isValidColor(value);
                break;
            case 'formIdList':
                isValid = isValidFormList(value);
                break;
            case 'logo':
                isValid = isValidLogo(value);
                break;
            default:
                break;
        }

        dispatch({ type: 'SET_VALID', field, isValid });
    };

    const isValidCustomerName = (value) => {
        return value.trim().length > 0;
    }
    const isValidColor = (value) => {
        return (value.trim().length > 0) && (value.trim().length < 7);
    }
    const isValidFormList = (value) => {
        return !(!value.length);
    }
    const isValidLogo = (value) => {
        return (Boolean(value));
    }


    const handleSubmit = (event: any) => {
        event.preventDefault();
        const customer: ICustomer = { customerId: selectedCustomer.customerId, customerName, isActive, isDeleted, primaryColor, secondaryColor, cloudFileName, logoUrl, createdBy, createdDate, modifiedBy, modifiedDate, logo, formIdList };
        handleSave(customer, MakeFormDataObject());
    };

    const isFormValid = isValid.customerName && isValid.primaryColor && isValid.secondaryColor && isValid.formIdList && isValid.logo;


    useEffect(() => {
        if (selectedCustomer.customerName) {
            validateField('customerName', selectedCustomer.customerName);
        }
        if (selectedCustomer.primaryColor) {
            validateField('primaryColor', selectedCustomer.primaryColor);
        }
        if (selectedCustomer.secondaryColor) {
            validateField('secondaryColor', selectedCustomer.secondaryColor);
        }
        if (selectedCustomer.formIdList) {
            validateField('formIdList', selectedCustomer.formIdList);
        }
    }, [selectedCustomer]);





    const handleFileUpload = useMemo(() => {
        return (newFile) => {
            setField("logo", newFile);
            setField("cloudFileName", newFile.FileDownloadName);
        };
    }, []);

    const MakeFormDataObject = () => {
        const formData = new FormData();
        if (logo) {
            formData.append("logo", base64ToBlob(logo.FileContents));
            formData.append("customerId", customerId.toString());
            formData.append("customerName", customerName);
            formData.append("isActive", isActive.toString());
            formData.append("createdBy", createdBy.toString());
            formData.append("createdDate", createdDate ? createdDate : "");
            formData.append("modifiedBy", modifiedBy.toString());
            formData.append("modifiedDate", modifiedDate ? modifiedDate : "");
            formData.append("isDeleted", isDeleted.toString());
            formData.append("primaryColor", primaryColor);
            formData.append("secondaryColor", secondaryColor);
            formData.append("cloudFileName", cloudFileName);
            formData.append("logoUrl", logoUrl);

            formIdList.forEach((formId, index) => {
                formData.append(`formIdList[${index}]`, formId);
            });

            return formData;
        }
        else {
            console.error('selectedImage is null or undefined.');
        }
    }



    useEffect(() => {
        validateField('logo', logo);
    }, [logo]);

    useEffect(() => {
        if (selectedCustomer.customerName) {
            GetCustomerLogo(cloudFileName).then(response => {
                setField('logo', response);
            });
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const request = {
                    userId: user.userId
                };
                const formList: IFormListItem[] = await GetFormList(request) ?? [];
                setForms(formList);
            } catch (error) {
                showSnackbar('Error fetching data', 'error', 5000);
            }
        }
        fetchData();
    }, [selectedCustomer]);


    return (
        <div>
            <Dialog
                fullScreen
                open={showDialog}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            {titleText}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={2} className={classes.shoFormContainer}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label="CustomerName"
                                value={customerName}
                                onChange={(event) => setField('customerName', event.target.value)}
                                onBlur={() => setTouched('customerName')}
                                error={!isValid.customerName && touched.customerName}
                                helperText={!isValid.customerName && touched.customerName ? "Please enter a valid customer name" : ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label="Primary Color"
                                value={primaryColor}
                                onChange={(event) => setField('primaryColor', event.target.value)}
                                onBlur={() => setTouched('primaryColor')}
                                error={!isValid.primaryColor && touched.primaryColor}
                                helperText={!isValid.primaryColor && touched.primaryColor ? "Please enter a valid hexidecimal color" : ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label="Secondary Color"
                                value={secondaryColor}
                                onChange={(event) => setField('secondaryColor', event.target.value)}
                                onBlur={() => setTouched('secondaryColor')}
                                error={!isValid.secondaryColor && touched.secondaryColor}
                                helperText={!isValid.secondaryColor && touched.secondaryColor ? "Please enter a valid hexidecimal color" : ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} marginTop={"12px"}>
                        <FormControl fullWidth>
                            <InputLabel>Forms</InputLabel>
                            <Select
                                labelId="role-label"
                                value={formIdList}
                                label="Forms"
                                multiple={true}
                                onBlur={() => setTouched('formIdList')}
                                error={!isValid.formIdList && touched.formIdList}


                                onChange={(event) => setField('formIdList', event.target.value)}
                            >
                                {
                                    Forms.map((option: IFormListItem) => (
                                        <MenuItem key={option.formId} value={option.formId}>
                                            {option.formName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid margin={"auto"} item xs={12} md={6}>
                        <DragDropFileUpload
                            onFileUpload={handleFileUpload}
                            myFile={logo}
                            title={cloudFileName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    value={isActive}
                                    checked={isActive}
                                    color="primary"
                                    onChange={(event) => setField('isActive', !isActive)}
                                />}
                            label="Status"
                            labelPlacement="top"
                        />
                    </Grid>

                    <Grid container alignItems={"center"} className={classes.shoFormContainer} >
                        <Grid item xs={12} md={1.2} margin={"auto"} >
                            <Button onClick={handleSubmit} variant="contained" disabled={!isFormValid}>
                                {customerId > 0 || createdDate ? "Update Customer" : "Create Customer"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
}
