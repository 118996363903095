import moment from "moment-timezone";
import ShoFormsClient from "../httpClient/ShoFormsClient";
import axios, { AxiosInstance } from "axios";

export const formatDateTime = (date: Date, timeZone: string = 'America/New_York') => {
  if (!date) return "N/A";
  return moment.utc(date).tz(timeZone).format("MMM Do YYYY, h:mm:ss A");
};

export const getCustomerList = async (Object) => {
  try {
    const instance = ShoFormsClient();
    const response = await instance.get("/Customer/GetCustomerList", Object);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SaveAccessCode = async (object) => {
  try {
    const instance: AxiosInstance = axios.create({
      baseURL: "https://api.dropboxapi.com",
    });
    const response = await instance.post("/oauth2/token", object);
    return response.data;
  } catch (error) {
    throw error;
  }
};
